import React from 'react';
import Slider from 'react-slick';
import { StaticImage } from 'gatsby-plugin-image';
import MainLayout from '../components/main-layout';
import SEO from '@/components/seo';

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true,
  variableWidth: true,
  centerMode: true,
};

const Bim: React.FC = () => (
  <>
    <SEO
      title="BIM"
      description="Informačné modelovanie budov, známe aj pod skratkou BIM, obsahuje produkty počítačového návrhu, ktoré sa pravidelne využívajú v architektúre a stavebníctve."
      image="/bim.jpg"
    />
    <MainLayout>
      <div
        id="bim"
        className="container m-auto text-white md:px-20 px-4 py-20 text-justify"
      >
        <h1 className="text-center text-3xl font-semibold mb-8">BIM</h1>
        <p>
          <strong>Informačné modelovanie budov</strong>, známe aj pod skratkou{' '}
          <strong>BIM</strong>, obsahuje produkty počítačového návrhu, ktoré sa
          pravidelne využívajú v architektúre a stavebníctve. Ide o moderný
          spôsob vizualizácie stavieb, a to pre ešte lepší pohľad na váš budúci
          domov či miesto podnikania.
        </p>
        <p className="mt-4 mb-10">
          BIM teda ponúka modelový proces navrhovania a správy budov, ako aj
          infraštruktúr, ktorý je nad rámec klasických stavebných výkresov, a
          taktiež generuje digitálne znázornenie funkčných vlastností
          zariadenia.
        </p>
        <div className="hidden md:block">
          <Slider {...settings}>
            <div className="px-4 h-full">
              <StaticImage
                className="h-full"
                src="../img/bim/1.jpg"
                alt="bim-1"
              />
            </div>
            <div className="px-4 h-full">
              <StaticImage
                className="h-full"
                src="../img/bim/2.jpg"
                alt="bim-2"
              />
            </div>
            <div className="px-4 h-full">
              <StaticImage
                className="h-full"
                src="../img/bim/3.jpg"
                alt="bim-3"
              />
            </div>
            <div className="px-4 h-full">
              <StaticImage
                className="h-full"
                src="../img/bim/4.jpg"
                alt="bim-4"
              />
            </div>
            <div className="px-4 h-full">
              <StaticImage
                className="h-full"
                src="../img/bim/5.jpg"
                alt="bim-5"
              />
            </div>
            <div className="px-4 h-full">
              <StaticImage
                className="h-full"
                src="../img/bim/6.jpg"
                alt="bim-6"
              />
            </div>
            <div className="px-4 h-full">
              <StaticImage
                className="h-full"
                src="../img/bim/7.jpg"
                alt="bim-7"
              />
            </div>
            <div className="px-4 h-full">
              <StaticImage
                className="h-full"
                src="../img/bim/8.jpg"
                alt="bim-8"
              />
            </div>
            <div className="px-4 h-full">
              <StaticImage
                className="h-full"
                src="../img/bim/9.jpg"
                alt="bim-9"
              />
            </div>
            <div className="px-4 h-full">
              <StaticImage
                className="h-full"
                src="../img/bim/10.jpg"
                alt="bim-10"
              />
            </div>
            <div className="px-4 h-full">
              <StaticImage
                className="h-full"
                src="../img/bim/11.jpg"
                alt="bim-11"
              />
            </div>
            <div className="px-4 h-full">
              <StaticImage
                className="h-full"
                src="../img/bim/12.jpg"
                alt="bim-12"
              />
            </div>
          </Slider>
        </div>
        <div className="block md:hidden">
          <StaticImage src="../img/bim/1.jpg" alt="bim-1" />
          <StaticImage src="../img/bim/2.jpg" alt="bim-2" />
          <StaticImage src="../img/bim/3.jpg" alt="bim-3" />
          <StaticImage src="../img/bim/4.jpg" alt="bim-4" />
          <StaticImage src="../img/bim/5.jpg" alt="bim-5" />
          <StaticImage src="../img/bim/6.jpg" alt="bim-6" />
          <StaticImage src="../img/bim/7.jpg" alt="bim-7" />
          <StaticImage src="../img/bim/8.jpg" alt="bim-8" />
        </div>
      </div>
    </MainLayout>
  </>
);

export default Bim;
